<template>
  <div>
    <div class="row table-utilities mx-0">
      <div class="row ml-2 w-100">
        <div class="col-md-10 search-section">
          <div class="input-group">
            <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div>
            <input
              type="text"
              class="form-control search-input"
              :placeholder="$t('sp_act.search')"
              @input="setPage"
              v-model="filterInput"
            />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <button
            class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
            @click="handleExport"
            :disabled="isLoading"
          >
            <div class="font-12">
              <div
                v-if="isLoading"
                class="spinner-border spinner-border-sm text-success mr-1"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
            </div>
            {{ $t("global.export") }}
          </button>
        </div>
      </div>
      <div class="row w-100 ml-2 mt-2">
        <div class="col-md-2 pl-0">
          <b-button
            class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
            id="popover-button-kegiatan"
            href="#"
            tabindex="0"
          >
            <div class="font-12">
              <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
            </div>
            Filter Data
          </b-button>
        </div>
        <b-popover
          placement="right"
          target="popover-button-kegiatan"
          variant="danger"
          triggers="focus"
        >
          <b-tabs content-class="mt-3">
            <b-tab title="Tanggal" active>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-today"
                  :value="filterDateTypes[0]"
                  @change="resetFilter1"
                  checked
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-today">
                  <div class="col">
                    <div class="row ssp-14">Today</div>
                    <div class="row">{{ today | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-yesterday"
                  :value="filterDateTypes[1]"
                  @change="resetFilter1"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-yesterday">
                  <div class="col">
                    <div class="row ssp-14">Yesterday</div>
                    <div class="row">{{ yesterday | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-week"
                  :value="filterDateTypes[2]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-week">
                  <div class="col">
                    <div class="row ssp-14">This week</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayWeek)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayWeek | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-month"
                  :value="filterDateTypes[3]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-month">
                  <div class="col">
                    <div class="row ssp-14">This month</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayMonth)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayMonth | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-custom"
                  :value="filterDateTypes[4]"
                  v-model="filterDateType"
                />
                <label class="form-check-label ssp-14" for="radio-custom">
                  Custom Range
                </label>
              </div>
              <b-row v-if="filterDateType == filterDateTypes[4]" class="mb-2">
                <b-col cols="6" class="pr-1">
                  <span>FROM</span>
                  <input
                    type="date"
                    class="form-control float-right"
                    v-model="filterSelected1"
                  />
                </b-col>
                <b-col cols="6" class="pl-1">
                  <span>TO</span>
                  <input
                    type="date"
                    class="form-control"
                    v-model="filterSelected2"
                  />
                </b-col>
              </b-row>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 ssp-14"
                :class="{ disabled: filterDateType === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="Nama SP">
              <div class="input-group">
                <div class="input-group-prepend bg-white">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="font-12">
                      <i class="ri-search-line ri-lg ri-sub"></i>
                    </div>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control search-input"
                  v-model="searchSP"
                />
              </div>
              <hr class="m-2" />
              <div class="filter-overflow-srch">
                <div class="form-checkbox" v-for="sp in dataSP" :key="sp.id">
                  <input
                    class="form-checkbox-item"
                    type="checkbox"
                    name="kegiatan-label"
                    :value="sp.id"
                    :id="sp.id"
                    v-model="filterSP"
                  />
                  <label class="form-checkbox-label ssp-14-400" :for="sp.id">
                    {{ sp.name }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterSP == '' }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="Kegiatan">
              <div class="filter-overflow">
                <div
                  class="form-checkbox"
                  v-for="kegiatan in kegiatanSP"
                  :key="kegiatan.id"
                >
                  <input
                    :id="kegiatan.value"
                    class="form-radio-item"
                    type="radio"
                    name="kegiatan-label"
                    :value="kegiatan.value"
                    v-model="filterKegiatan"
                  />
                  <label
                    class="form-radio-label ssp-14-400"
                    :for="kegiatan.value"
                  >
                    {{ kegiatan.label }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterKegiatan === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
          </b-tabs>
        </b-popover>
      </div>
    </div>
    <div class="table-borderless">
      <b-table-simple hover responsive>
        <b-thead class="bv-head">
          <b-tr>
            <!-- <b-th><input type="checkbox" @click="selectAll" v-model="allSelected"></b-th> -->
            <b-th>#</b-th>
            <b-th v-for="hd in $t('sp_act.headers')" :key="hd.id">
              {{ hd }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="dataKegiatan.length !== 0">
          <!-- <b-tbody v-if="this.dataLikuidasi.length !== 0"> -->
          <b-tr
            class="ssp-16-400"
            v-for="(data, index) in dataKegiatan"
            :key="data.id"
          >
            <!-- <b-td><input type="checkbox" :value="data.id" v-model="kegIds" @click="select"></b-td> -->
            <b-td>{{ ++index + (currPage - 1) * perPage }}</b-td>
            <b-td>{{ data.sales_promotor.name }}</b-td>
            <b-td>{{ data.tanggal_kegiatan | formatDate2 }}</b-td>
            <b-td>{{ data.lokasi }}</b-td>
            <b-td>
              <span
                v-if="data.kegiatan === 'DEMOPLOT'"
                class="badge badge-brown"
                >{{ convertKegiatan(data.kegiatan) }}</span
              >
              <span v-if="data.kegiatan === 'FFD'" class="badge badge-orange">{{
                convertKegiatan(data.kegiatan)
              }}</span>
              <span v-if="data.kegiatan === 'SFM'" class="badge badge-red">{{
                convertKegiatan(data.kegiatan)
              }}</span>
              <span
                v-if="data.kegiatan === 'SPRAY'"
                class="badge badge-purple-2"
                >{{ convertKegiatan(data.kegiatan) }}</span
              >
              <span v-if="data.kegiatan === 'ODP'" class="badge badge-yellow">{{
                convertKegiatan(data.kegiatan)
              }}</span>
              <span v-if="data.kegiatan === 'BFM'" class="badge badge-gray">{{
                convertKegiatan(data.kegiatan)
              }}</span>
              <span v-if="data.kegiatan === 'KFM'" class="badge badge-green">{{
                convertKegiatan(data.kegiatan)
              }}</span>
              <span v-if="data.kegiatan === 'SCP'" class="badge badge-white">{{
                convertKegiatan(data.kegiatan)
              }}</span>
              <span v-if="data.kegiatan === 'SCK'" class="badge badge-purple">{{
                convertKegiatan(data.kegiatan)
              }}</span>
            </b-td>
            <b-td class="action-column">
              <div class="action-wrapper font-12">
                <i
                  class="ri-eye-fill ri-lg ri-mid action-btn"
                  @click="onDetail(data)"
                ></i>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <b-tr>
            <b-td colspan="10">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{
                      orange: currPage < totalPages
                    }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-detail" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <table class="tabel-detail">
          <tbody>
            <tr>
              <th>{{ $t("sp_act.label.sp") }}</th>
              <td>:</td>
              <td>
                <strong>{{ detailKegiatan.sales_promotor.name }}</strong>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.act_type") }}</th>
              <td>:</td>
              <td>
                <strong>{{ convertKegiatan(detailKegiatan.kegiatan) }}</strong>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.date") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.tanggal_kegiatan | formatDate2 }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.loc") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.lokasi }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'DEMOPLOT'">
            <tr>
              <th>{{ $t("sp_act.label.farmer") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.name }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>HST</th>
              <td>:</td>
              <td>{{ detailKegiatan.hst }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.opt") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.opt_sasaran }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.landarea") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.luas_demoplot }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ap_date") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.tanggal_aplikasi | formatDate2 }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ob_date") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.tanggal_pengamatan | formatDate2 }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ap_prod") }}</th>
            </tr>
            <tr
              v-for="(prod, index) in detailKegiatan.aplikasiKegiatan"
              :key="prod.id"
            >
              <td>- {{ $t("sp_act.label.prod") }} {{ ++index }}</td>
              <td>:</td>
              <td>
                {{ prod.produk ? prod.produk.brand : "-" }}
                ({{ prod.konsentrasi_rekomendasi }} ml/L, gr/L)
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.vol") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.volume }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.res") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kesimpulan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SPRAY'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>HST</th>
              <td>:</td>
              <td>{{ detailKegiatan.hst }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.opt") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.opt_sasaran }}</td>
            </tr>
            <!-- <tr>
              <th>Luas Lahan Spray</th>
              <td>:</td>
              <td>{{ detailKegiatan.opt_sasaran }}</td>
            </tr> -->
            <tr>
              <th>{{ $t("sp_act.label.ap_prod") }}</th>
            </tr>
            <tr
              v-for="(prod, index) in detailKegiatan.aplikasiKegiatan"
              :key="prod.id"
            >
              <td>- {{ $t("sp_act.label.prod") }} {{ ++index }}</td>
              <td>:</td>
              <td>
                {{ prod.produk ? prod.produk.brand : "-" }}
                ({{ prod.konsentrasi_rekomendasi }} ml/L, gr/L)
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.vol") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.volume }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.farmer_apl") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_aplikator }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.sales_act") }}</th>
              <td>:</td>
              <td>
                <span v-if="detailKegiatan.isExist === true">Ada</span>
                <span v-else>Tidak Ada</span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'FFD'">
            <tr>
              <th>{{ $t("sp_act.label.farmer") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.name }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'ODP'">
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SFM'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'BFM'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'KFM'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody
            v-if="
              (detailKegiatan.isExist === true &&
                detailKegiatan.kegiatan == 'SPRAY') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'FFD') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'ODP') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'SFM') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'BFM') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'SFM')
            "
          >
            <tr v-if="detailKegiatan.retailers">
              <th>{{ $t("sp_act.label.ret") }}</th>
            </tr>
            <tr>
              <td v-if="detailKegiatan.retailers">
                <tr>
                  - Level Toko
                </tr>
                <tr>
                  -
                  {{
                    $t("sp_act.label.ret_name")
                  }}
                </tr>
                <tr>
                  -
                  {{
                    $t("sp_act.label.ret_addr")
                  }}
                </tr>
                <tr v-if="detailKegiatan.retailers.alamat.length > 45">
                  &nbsp;
                </tr>
                <tr>
                  - Nama Pemilik Toko
                </tr>
                <tr>
                  -
                  {{
                    $t("sp_act.label.ret_phone")
                  }}
                </tr>
              </td>

              <td v-if="detailKegiatan.retailers">
                <tr>
                  :
                </tr>
                <tr>
                  :
                </tr>
                <tr>
                  :
                </tr>
                <tr v-if="detailKegiatan.retailers.alamat.length > 45">
                  &nbsp;
                </tr>
                <tr>
                  :
                </tr>
                <tr>
                  :
                </tr>
              </td>

              <td v-if="detailKegiatan.retailers">
                <tr>
                  {{
                    detailKegiatan.retailers.retailer_type
                  }}
                </tr>
                <tr>
                  {{
                    detailKegiatan.retailers.name
                  }}
                </tr>
                <tr>
                  {{
                    detailKegiatan.retailers.alamat
                      ? detailKegiatan.retailers.alamat
                      : "-"
                  }}
                </tr>
                <tr>
                  {{
                    detailKegiatan.retailers.owner
                  }}
                </tr>
                <tr>
                  {{
                    detailKegiatan.retailers.mobile
                      ? detailKegiatan.retailers.mobile
                      : "-"
                  }}
                </tr>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="
              detailKegiatan.kegiatan == 'SPRAY' ||
                detailKegiatan.kegiatan == 'FFD' ||
                detailKegiatan.kegiatan == 'ODP' ||
                detailKegiatan.kegiatan == 'SFM' ||
                detailKegiatan.kegiatan == 'BFM' ||
                detailKegiatan.kegiatan == 'KFM'
            "
          >
            <tr>
              <th>
                {{ $t("sp_act.label.sales") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_hasil_penjualan | formatRp }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.farmer_buying") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_beli_langsung }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.budget") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.budget | formatRp }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.reward") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.hadiah }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.liq_product") }}
              </th>
              <td>:</td>
              <td>
                <span v-if="detailKegiatan.produk_liquidasi === true">Ada</span>
                <span v-else>Tidak Ada</span>
              </td>
            </tr>
            <tr
              v-for="(prod, index) in detailKegiatan.LiquidasiKegiatan"
              :key="prod.id"
            >
              <td>- {{ $t("sp_act.label.prod") }} {{ ++index }}</td>
              <td>:</td>
              <td>
                {{ `${prod.produk.brand} (${prod.jumlah_produk}) Pcs/Btl` }}
              </td>
            </tr>
            <tr></tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SCP'">
            <tr>
              <th>{{ $t("sp_act.label.farmer") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.name }}
              </td>
            </tr>
            <tr>
              {{
                $t("sp_act.label.ret_addr")
              }}
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.alamat }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ret_phone") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.hp }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.landarea") }}</th>
              <td>:</td>
              <td>
                {{ detailKegiatan.luas_demoplot }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.main_crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                <span v-if="detailKegiatan.petanis.ocrops != null">{{
                  detailKegiatan.petanis.ocrops.crop
                }}</span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.other_crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                <span v-if="detailKegiatan.petanis.ocrops != null">{{
                  detailKegiatan.petanis.ucrops.crop
                }}</span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">
                {{ $t("sp_act.label.call_prp") }}
              </th>
              <td style="vertical-align: top;">:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  {{
                    ` - ${keg.tujuan_sales_call_petani}`
                  }}
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.call_res") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kesimpulan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SCK'">
            <tr>
              <th>{{ $t("sp_act.label.ki_name") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.name }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ki_owner") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.owner }}
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">
                {{ $t("sp_act.label.ki_addr") }}
              </th>
              <td style="vertical-align: top;">:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.alamat }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ret_phone") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.mobile }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ki_stat") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.level_toko }}</td>
            </tr>
            <tr>
              <th style="vertical-align: top;">
                {{ $t("sp_act.label.call_prp") }}
              </th>
              <td style="vertical-align: top;">:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  {{
                    ` - ${keg.tujuan_sales_call_kios}`
                  }}
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pop") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.po_produk !== 'null'">{{
                    keg.po_produk
                  }}</span>
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.sup_act") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.support_kegiatan !== 'null'">{{
                    keg.support_kegiatan
                  }}</span>
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.stock_val") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.stok !== null">{{ keg.stok }}</span>
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pay") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.pembayaran !== 'null'">{{
                    keg.pembayaran
                  }}</span>
                </tr>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>{{ $t("dailyreport.label.doc") }}</th>
              <td>:</td>
              <img
                v-if="detailKegiatan.dokumentasi"
                :src="url + detailKegiatan.dokumentasi"
                alt="dokumentasi"
              />
              <span v-else>
                {{ $t("global.no_doc") }}
              </span>
            </tr>
          </tbody>
        </table>

        <form v-if="isLikuidasi">
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black"
              >Tanggal Laporan</label
            >
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.tanggal"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black"
              >Nama Sales Promotor</label
            >
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.sp"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">{{
              $t("liquidation.label.product")
            }}</label>
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.produk"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">{{
              $t("liquidation.label.rp")
            }}</label>
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.harga"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">Quantity</label>
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.qty"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">Value</label>
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.value"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.vr") }} 2</label
            >
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.r2"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.vr") }} 1</label
            >
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="detailLikuidasi.r1"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">{{
              $t("liquidation.label.inv")
            }}</label>
            <div class="col-md-10 offset-1 mt-3">
              <img
                v-if="detailLikuidasi.invoice != null"
                class="d-block ml-auto mr-auto"
                :src="url + detailLikuidasi.invoice"
                :alt="detailLikuidasi.invoice"
              />
              <div v-else class="mx-auto text-center">
                {{ $t("feedback.not_avb") }}
              </div>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import jwtService from "@/core/services/jwt.service";
import { KegiatanSP, RoleType, filterDateType } from "@/graphql/enum.type";
import { Q_LIST_KEGIATAN } from "@/graphql/queries";
// import * as XLSX from "xlsx";
import moment from "moment";
import { Q_LIST_USERSEARCH } from "@/graphql/queries";
import Axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      url: jwtService.getURL(),
      dataKegiatan: [],
      details: {},

      dataSP: [],
      dataSPID: [],
      searchSP: "",
      filterSP: [],

      dataProduk: [],
      searchProduk: "",
      filterProduk: [],

      isDetail: true,
      currPage: 1,
      perPage: 10,
      totalPages: null,
      totalData: null,
      filterInput: "",
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,
      filterKegiatan: null,
      role: jwtService.getRole(),

      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],
      kegiatanSP: [
        KegiatanSP.DEMOPLOT,
        KegiatanSP.SPRAY,
        KegiatanSP.FFD,
        KegiatanSP.ODP,
        KegiatanSP.SFM,
        KegiatanSP.BFM,
        KegiatanSP.KFM,
        KegiatanSP.SCP,
        KegiatanSP.SCK
      ],
      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null
    };
  },
  apollo: {
    listLaporanKegiatan: {
      query: Q_LIST_KEGIATAN,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          sp_id: this.filterSP,
          tss_id: [],
          region_id: this.role === RoleType.RM ? this.getRegionID() : [],
          prov_id: this.role === RoleType.RM ? [] : this.getProvinceID(),
          kab_id: [],
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          dateType: this.filterDateType,
          kegiatan: this.filterKegiatan
        };
      },
      result({ data }) {
        this.dataKegiatan = data.listLaporanKegiatan.lapkegs;
        this.totalPages = data.listLaporanKegiatan.totalPage;
        this.totalData = data.listLaporanKegiatan.total;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchSP,
          role: RoleType.SP,
          country_id: [],
          region_id: this.role === RoleType.RM ? this.getRegionID() : [],
          prov_id: this.role === RoleType.RM ? [] : this.getProvinceID(),
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.dataSP = data.listUser.users;
        this.dataSPID = data.listUser.users.map(a => {
          return a.id;
        });
      }
    }
  },
  methods: {
    getDay(value) {
      return moment(value).format("DD");
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.filterKegiatan = null;
      this.filterProduk = [];
      this.filterSP = [];
    },
    resetFilter1() {
      this.filterSelected2 = "";
    },
    setPage() {
      this.currPage = 1;
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    onDetail(data) {
      this.modalTitle = this.$t("sp_act.detail");
      this.detailKegiatan = data;
      this.isLikuidasi = false;
      this.isDisabled = true;
      this.$bvModal.show("modal-detail");
    },
    resetForm() {
      this.details = {};
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/laporan-kegiatan/excel",
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user: this.filterSP.length === 0 ? this.dataSPID : this.filterSP,
          region: [],
          kegiatan: this.convertKegSP(this.filterKegiatan)
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + jwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `kegiatansp-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    },
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "DEMOPLOT":
          Value = "Demo Plot";
          break;
        case "FFD":
          Value = "FFD";
          break;
        case "SFM":
          Value = "Small Farmer Meeting";
          break;
        case "SPRAY":
          Value = "Spray Massal";
          break;
        case "ODP":
          Value = "ODP";
          break;
        case "BFM":
          Value = "Big Farmer Meeting";
          break;
        case "KFM":
          Value = "Key Farmer Meeting";
          break;
        case "SCP":
          Value = "Sales Call Petani";
          break;
        case "SCK":
          Value = "Sales Call Kios";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  }
};
</script>
